<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" style="max-width: 200px; min-width: 75px">
              <SelectMultipleOffice
                :label="$t('labels.office')"
                :placeholder="$t('labels.office')"
                name="id_office"
                :default-value="filters.id_poss"
                @onFilter="onFilterChange"
                class="input-filter"
              />
            </th>
            <th role="columnheader" style="max-width: 250px; min-width: 95px">
              <SelectMultipleEmployee
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="id_employees"
                class="input-filter"
                :default-value="filters.id_employees"
                :id-poss="filters.id_poss"
                :filters="filters"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader" style="max-width: 200px; min-width: 95px">
              <DateRangeFilter
                :label="$t('labels.date')"
                :placeholder="$t('labels.date')"
                name="dates"
                sort-name="dates"
                :default-value="filters.dates"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!--<SelectFilter :options="indexOptions" label="Ca" placeholder="Ca" name="index" sort-name="index" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>-->
            <template v-if="viewOption === 'view0'">
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.shift") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.checkin_time") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.checkout_time") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.timekeeping_late") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.timekeeping_early") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.checkin_image") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                {{ $t("labels.checkout_image") }}
              </th>
            </template>
            <template v-if="viewOption === 'view1'">
              <!-- Σ -->
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                Đi muộn
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                Về sớm
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 80px"
              >
                Không checkout
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-for="item in items" :key="item.id">
            <td>{{ item.office_code }}</td>
            <td>{{ item.identity_name }}</td>
            <template v-if="viewOption === 'view0'">
              <td>{{ formatDateTime(item.date, "DD/MM/YYYY") }}</td>
              <td>{{ item.working_time_name }}</td>
              <td>{{ item.checkin_time }}</td>
              <td>{{ item.checkout_time }}</td>
              <td>{{ item.late }}</td>
              <td>{{ item.early }}</td>
              <td>
                <TimekeepingImage
                  v-if="item.checkin_image"
                  :url="item.checkin_image"
                />
              </td>
              <td>
                <TimekeepingImage
                  v-if="item.checkout_image"
                  :url="item.checkout_image"
                />
              </td>
            </template>
            <template v-if="viewOption === 'view1'">
              <td>
                {{ item.count_checkin }}
                <small
                  >/ {{ item.total_shift }}
                  <span class="grey--text">ca</span></small
                >
              </td>
              <td>
                {{ item.sum_late }} <small class="grey--text">phút</small>
              </td>
              <td>
                {{ item.sum_early }} <small class="grey--text">phút</small>
              </td>
              <td>
                {{ item.sum_miss_checkout }}
                <small class="grey--text">lần</small>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-autocomplete
          dense
          outlined
          hide-details
          v-model="viewOption"
          :items="viewOptions"
          label="View mode"
          placeholder="View mode"
          @change="getList"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "TimekeepingList",
  components: {
    SelectMultipleOffice: () =>
      import("@/components/table/SelectMultipleOffice"),
    SelectMultipleEmployee: () =>
      import("@/components/table/SelectMultipleEmployee"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    TimekeepingImage: () => import("@/components/timekeeping/TimekeepingImage"),
  },
  props: {
    pFilters: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    filters: {},
    items: [],
    indexTxt: {},
    indexOptions: [],
    viewOption: "view0",
    viewOptions: [
      {
        text: "Chi tiết",
        value: "view0",
      },
      {
        text: "Tổng hợp",
        value: "view1",
      },
    ],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.filters = { ...this.pFilters };
    const indexTxt = {
      1: this.$t("labels.morning"),
      2: this.$t("labels.afternoon"),
      3: this.$t("labels.evening"),
      0: "???",
    };
    const indexOptions = [
      {
        text: indexTxt[1],
        value: 1,
      },
      {
        text: indexTxt[2],
        value: 2,
      },
    ];
    this.indexOptions = [...indexOptions];
    this.indexTxt = { ...indexTxt };
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      httpClient
        .post("/timekeeping-list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }

      this.isLoading = true;
      try {
        await httpClient.post("/export-request-create", {
          params: this.filters,
          type: "timekeeping",
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.waiting_file_download"));
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
